// core
import React, {useEffect, useState} from 'react'
import { useLocation } from "@reach/router";
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from 'uuid'

// Components
import Header from './Header'
import Footer from './Footer'
import { useConsent } from '../components/ConsentContext'

// constants
import AgeGate from "./AgeGate";
import NewsLetterSignUpPopUp from './NewsLetterSignUpPopUp'

// dataLayers
import { setGlobalLangAndCountryEvent } from "../assets/js/data-layer";


const Layout = ({children, currentPage, thankYouPage, activeDocMeta, setPreviousPage = true}) => {
  const location = useLocation()
  const [showAgeGate, setShowAgeGate] = useState(false)
  const [cookies, setCookie] = useCookies()
  const persID = uuidv4();
  const { lang } = activeDocMeta

  const consentAccepted = useConsent()

  // Get Date
  let currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 7)
  let aWeekFromCurrentDate = currentDate

  // Persistent ID
  useEffect(()=>{
    if(!cookies?.persID) {
      setCookie('persID', persID, { expires: aWeekFromCurrentDate, path: '/' })
    }
  }, [cookies?.persID])

  const ageGateValid = () => {
    setCookie('adult', true, {expires: aWeekFromCurrentDate, path: '/'})
  }

  useEffect(() => {
   
    
      if(cookies?.adult){
        setShowAgeGate(false)
      } else {
        setShowAgeGate(true)
      }
    

    setCookie('wcUserURL', location?.pathname)

  }, [cookies?.adult, cookies?.wcUserURL])

  // Datalayer Global lang and country
  useEffect(()=>{
    if(activeDocMeta.lang){
      setGlobalLangAndCountryEvent(activeDocMeta.lang, consentAccepted)
    }
  }, [activeDocMeta.lang, consentAccepted])

  useEffect(() => {
    // set previously visited page
    if(setPreviousPage){
      sessionStorage.setItem("previous_page_url", location.pathname)
    }
  })

  return (
      <>
        { showAgeGate && <AgeGate setAgeValid={ageGateValid} activeDocMeta={activeDocMeta}/>}
        {/* Header */}
        <Header customPage={currentPage || ''} activeDocMeta={activeDocMeta} />

        <main id={ currentPage || null } className={thankYouPage && "thank-you"}>{children}</main>

        {/* Footer */}
        <Footer activeDocMeta={activeDocMeta}/>

        {/* NewsLetter PopUp */}
        <NewsLetterSignUpPopUp lang={lang} />
      </>
  )
}


export default Layout