import React, { useEffect } from 'react'

import { Link } from 'gatsby'
import { useLocation } from "@reach/router";

//components
import ImgWithFallback from "../components/ImgWithFallback";

// data-layer
import { setLearnMoreClickedEvent, setBuyNowClickedEvent } from "../assets/js/data-layer";
import { useConsent } from '../components/ConsentContext'


export const PromoModule = ({ slice }) => {
   const promoItems = slice.items
  const {title} = slice.primary
  const location = useLocation()
  const consentAccepted = useConsent()

  const handleBuyNowDlEvent = ( {url} ) => {
    // buy now clicked event - link to locator
    if(url && url === '/locator'){
      setBuyNowClickedEvent({
        flavor: slice.flavor? slice.flavor : '',
        category: slice.category? slice.category : ''
      }, consentAccepted)
    }
  }

  return (
      <section className='promo-module pb-5'>
          <div className='promo-module__content'>
            <h4 className='promo-module__title'>
              {title.text}
            </h4>
            <div className='row promo-module__cards'>
            {promoItems.map(({ image,
                               subtitle,
                               button_link,
                               button_text}) => {
              return <div key={subtitle.text} className='text-center'>
                <Link
                  to={button_link.url || ''}
                  target="_blank"
                  title={subtitle.text}
                  onClick={() => {
                    handleBuyNowDlEvent(button_link)
                  }}
                >
                  <ImgWithFallback
                    classNameImg='w-100 promo-module__image'
                    src={image.url}
                    alt={image.alt}
                    fallbackSrc={image.url}
                  />
                </Link>
                <div className='promo-module__content-wrap'>
                  <h5 className='promo-module__subtitle'>
                    {subtitle.text}
                  </h5>
                  <Link
                      to={button_link.url || ''}
                      target="_blank"
                      title={subtitle.text}
                      className="button-white promo-module__button"
                      onClick={()=> {
                        const dl = {
                          url: button_link.url,
                          referrer: location.pathname,
                          name: button_text.text.toLowerCase()
                        }
                        setLearnMoreClickedEvent(dl, consentAccepted)

                        handleBuyNowDlEvent(button_link)
                      } }
                  >
                    {button_text.text}
                  </Link>
                </div>
              </div>
            })}
            </div>
          </div>
      </section>
  )
}