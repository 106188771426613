import React from 'react'

export const LocatorHero = ({slice}) => {
  const title = slice.primary.title?.text || ''
  const banner = slice.primary.banner?.url || ''
  const locatorDescription = slice.primary.locator_description?.html || ''

  return <>
    { banner &&
      <section className='prod-locator__hero' style={{backgroundImage: `url(${banner})`}}>
        {title && <h1 className='prod-locator__title'>{title}</h1>}
      </section>
    }
    {locatorDescription &&
       <section className='prod-locator__key-message'>
        <div className='prod-locator__key-message-text' dangerouslySetInnerHTML={{__html: locatorDescription }} />
      </section>
    }
  </>
}
