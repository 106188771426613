import React, {  useEffect } from 'react'
import {graphql} from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

// components
import Layout from "../components/Layout";
import Seo from "../components/Seo"
import { SliceZone } from "@prismicio/react";

import { components } from "../slices/homepage";


const Homepage = ({ data }) => {
  // console.log("homepage", data.prismicHomepage)
  const isBrowser = typeof window !== "undefined"

  const pageContent = data.prismicHomepage
  const { body: slices, meta_title, meta_description, social_card} = data.prismicHomepage.data

  const { lang, type, url } = data.prismicHomepage
  const alternateLanguages = data.prismicHomepage.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  useEffect(() => {
    if(isBrowser) {
      if(lang === 'en-us')
        window.history.replaceState({}, null, '/');
      else
        window.history.replaceState({}, null, '/es/');
    }
  }, []);

  return (
      <>
        <Layout currentPage='homepage' activeDocMeta={activeDoc}>
          <Seo
              title={meta_title?.text }
              description={meta_description?.text}
              image={social_card?.url}
              activeDocMeta={activeDoc}
          />
          <SliceZone components={components} slices={slices} context={{data: pageContent, activeDoc}}/>
        </Layout>
      </>
  )
}

export const query = graphql`
  query HomepageQuery ($id: String, $lang: String) {
     prismicHomepage (id: { eq: $id }, lang: { eq: $lang }) {
    url
    type
    lang
    id
    alternate_languages {
       id
       type
       lang
    }
    data {
      meta_description {
        richText
        text
      }
      meta_title {
        richText
        text
      }
      social_card {
        url
      }
      body {
        ... on PrismicHomepageDataBodyComingSoon {
          id
          slice_type
          primary {
            description {
              richText
              text
            }
            image {
              alt
              url
            }
          }
        }
        ... on PrismicHomepageDataBodyHeroSection {
          id
          slice_type
          primary {
            additional_class_name
            background_color
            cta_button_text {
              richText
              text
            }
            cta_button_link {
              url
            }
            background_image {
              alt
              url
            }
            background_image_mobile {
              alt
              url
            }
          }
        }
        ... on PrismicHomepageDataBodyFindNearYou {
          id
          slice_type
          items {
            icon {
              url
              alt
            }
            icon_title {
              richText
              text
            }
          }
          primary {
            button {
              url
              uid
            }
            button_text {
              richText
              text
            }
            description {
              text
              richText
            }
            title {
              text
              richText
            }
          }
        }
        ... on PrismicHomepageDataBodyHeroVideoSection {
          id
          slice_type
          primary {
            page_language
            background_color
            button_text {
              text
              richText
            }
            button_link {
              url
            }
          }
        }
        ... on PrismicHomepageDataBodyProductCarousel {
          id
          slice_type
          primary {
            background_image {
              url
              alt
            }
            category {
              document {
                ... on PrismicProductCategory {
                  id
                  data {
                    category_new_text
                    category_name
                    taxonomy_product_category
                    category_item {
                      category_item_link {
                        document {
                          ... on PrismicProductDetailsPage {
                            id
                            url
                            data {
                              product_name {
                                text
                                richText
                              }
                              product_image {
                                url
                                alt
                              }
                              product_type
                              product_new_text
                              taxonomy_product_size
                              taxonomy_product_flavor
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on PrismicHomepageDataBodyInstagramGallery {
            id
            slice_type
        }
        ... on PrismicHomepageDataBodyProductRange {
          id
          slice_type
          items {
            category {
              document {
                ... on PrismicProductCategory {
                  id
                  data {
                    category_logo {
                      url
                      alt
                    }
                    category_name_prefix
                    category_name
                    taxonomy_product_category
                    category_name_suffix
                    category_new_text
                    category_tagline
                    category_bg_image {
                      url
                      alt
                    }
                    category_bg_image_mobile{
                      url
                      alt
                    }
                    category_info {
                      product_info_icon {
                        url
                        alt
                      }
                      product_info_name {
                        text
                        richText
                      }
                    }
                    category_info_subtitle {
                      richText
                    }
                    category_item {
                      category_item_link {
                        document {
                          ... on PrismicProductDetailsPage {
                            id
                            url
                            data {
                              product_name {
                                text
                                richText
                              }
                              product_image {
                                url
                                alt
                              }
                              product_image_carousel{
                                url
                                alt
                              }
                              product_type
                              product_new_text
                              taxonomy_product_size
                              taxonomy_product_flavor
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on PrismicHomepageDataBodyYoutubeVideoSection {
          id
          slice_type
          primary {
            full_view_width
            video_poster_desktop {
              alt
              url
            }
            video_poster_mobile {
              alt
              url
            }
            section_title {
              richText
              text
            }
            play_button_text {
              richText
              text
            }
            play_button_text_color
            play_button_image {
              alt
              url
            }
            youtube_src {
              text
              richText
            }
          }
        }
        ... on PrismicHomepageDataBodyCrosslink {
          id
          slice_type
          primary {
            cta_button_text {
              text
              richText
            }
            cta_button_link {
              url
            }
          }
        }
        ... on PrismicHomepageDataBodySubscribeForm {
          id
          slice_type
        }
        ... on PrismicHomepageDataBodyFullWidthImageText {
          id
          slice_type
          primary {
            cta_button { 
              url
            }
            cta_button_text { 
              richText
              text
           }
            background_image {
             url
              alt
            }
            image_fixed_height
            image_height_desktop
            image_height_tablet
            image_height_mobile
          }
          items {
            text_size
            text_color
            text_space_bottom
            text {
              richText
            }
          }
        }
        ... on PrismicHomepageDataBodyFullWidthText {
          id
          slice_type
          items {
            vertical_spacing
            text {
              richText
            }
            text_size
            text_max_width_desktop
            text_max_width_tablet
            text_color
            background_color
          }
        }
        ... on PrismicHomepageDataBodyInstagramSection {
          id
          slice_type
          primary {
            title {
              richText
              text
            }
            description {
              richText
              text
            }
            copy_color
            background_color
            widget_view
          }
        }
        ... on PrismicHomepageDataBodyTestimonials {
          id
          slice_label
          slice_type
          items {
            publication_logo {
              url
              gatsbyImageData(sizes: "75px")
              alt
            }
            publication_name {
              richText
              text
            }
            quote {
              text
              richText
            }
            star_rating
          }
        }
      }
    }
  }
}
`

export default withPrismicPreview(Homepage)