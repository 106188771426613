// core
import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useCookies } from 'react-cookie'

// assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

// components
import NewsLetterSignUp from './NewsLetterSignUp'
import { useConsent } from '../components/ConsentContext'

// constants
import {SUBSCRIPTION_FORM} from "../constants/subscriptionFormLocation";
import { ACTIVE_CAMPAIGN_TAG } from "../constants"
import { VALIDATION_EN, VALIDATION_ES } from "../constants/formValidation"

const NewsLetterSignUpPopUp = ({ lang }) => {
    const [showNewsLetterPopUp, setShowNewsLetterPopUp] = useState(false)
    const closeNewsLetterPopUp = () => setShowNewsLetterPopUp(false)
    const [cookies, setCookie] = useCookies(['newsLetter'])

    const validations = lang === "en-us" ? VALIDATION_EN : VALIDATION_ES

    // Newsletter Signup Set Values
    const subscriptionFormTitle = validations.signupFormPopupTitle
    const subscriptionFormCopy = validations.signupFormPopupCopy
    const subscriptionFormSubmitBtn = validations.signupFormPopupSubmitBtn
    const subscriptionFormName = SUBSCRIPTION_FORM.POP_UP
    const activeCampaignTag = ACTIVE_CAMPAIGN_TAG.POP_UP

    // Get Date
    let currentDate = new Date()
    currentDate.setDate(currentDate.getDate() + 7)
    let aWeekFromCurrentDate = currentDate
    const isBrowser = typeof window !== "undefined"

    const handleScroll = () => {
        if (cookies?.adult && isBrowser && window.scrollY >= 500) {
            if (cookies['newsLetter'] === "false" || !cookies['newsLetter']) {
                setCookie('newsLetter', true, {expires: aWeekFromCurrentDate, path: '/'})
                setShowNewsLetterPopUp(true)
                window.removeEventListener("scroll", handleScroll)
            }
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(!showNewsLetterPopUp){
            return
        }
    }, [showNewsLetterPopUp])

    return(
        <Modal show={showNewsLetterPopUp} onHide={closeNewsLetterPopUp} animation={false} className="modal__newsletter-form">
            <Modal.Header className="position-relative">
                <button className="close-btn" data-dismiss="modal" aria-label="Close" onClick={closeNewsLetterPopUp} onKeyDown={closeNewsLetterPopUp}>
                    <FontAwesomeIcon icon={faXmark} className="fa-solid"/>
                </button>
            </Modal.Header>
            <Modal.Body>
                {/* Newsletter Signup */}
                <NewsLetterSignUp
                    subscriptionFormTitle={subscriptionFormTitle}
                    subscriptionFormCopy={subscriptionFormCopy}
                    subscriptionFormName={subscriptionFormName}
                    submitBtnText={subscriptionFormSubmitBtn}
                    activeCampaignTag={activeCampaignTag}
                    lang={lang}
                />
            </Modal.Body>
        </Modal>
    )
}

export default NewsLetterSignUpPopUp