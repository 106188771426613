// core
import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import Accordion from "react-bootstrap/Accordion"
import { scroller } from "react-scroll";
import { useLocation } from '@reach/router';

// components
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import CommonPageBanner from '../components/CommonPageBanner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

const PrivacyPolicy = ({data}) => {
    const location = useLocation()
    const pageContent = data.prismicPrivacyPolicyPage
    const pageData = data.prismicPrivacyPolicyPage.data
    const [activeKey, setActiveKey] = useState(null)
    const [baseURL] = useState(location?.href)
    const isBrowser = typeof window !== "undefined"
    // console.log('pageData ' , pageData)

    // pageData.collapse_item = undefined
    // pageData.collapse_item[0] = undefined
    // pageData.collapse_item[0].section_header = undefined
    // pageData.collapse_item[0].item_id = undefined
    // pageData.collapse_item[0].item_title = undefined
    // pageData.collapse_item[0].item_content.text = undefined
    // pageData.collapse_item[0].item_content.html = undefined

    const { lang, type, url } = pageContent
    const alternateLanguages = pageContent.alternate_languages || []
    const activeDoc = {
      lang,
      type,
      url,
      alternateLanguages,
    }

    const handleAccordionToggle = (eventKey, eventObj) => {
      setActiveKey(eventKey === activeKey ? null : eventKey)
    }

    const handlePrivacyCenterClick = (event) => {
      event.preventDefault()
      window.semaphore.push(['showPreferences'])
    }

    useEffect(() => {
      const privacyCenterLinks = document.querySelectorAll('.privacy-center-toggle')
      privacyCenterLinks.forEach(link => {
        link.addEventListener('click', handlePrivacyCenterClick)
      })

      return () => {
        privacyCenterLinks.forEach(link => {
          link.removeEventListener('click', handlePrivacyCenterClick)
        })
      }
    }, [])

    useEffect(() => {
      if (!isBrowser) {
        return
      } else if(isBrowser && baseURL.includes('#')) {
        const splitURL = baseURL.split('#')
        // console.log('splitURL ', splitURL)
        scroller.scrollTo(splitURL[1], {
          duration: 1000,
          delay: 50,
          smooth: true,
        });
      }
    }, [isBrowser, baseURL])

    return (
      <Layout currentPage="privacy-policy" activeDocMeta={activeDoc}>
        <Seo
            title={pageData.meta_title?.text }
            description={pageData.meta_description?.text}
            image={pageData.social_card?.url}
            activeDocMeta={activeDoc}
        />
        <section id="privacy-policy" className="inside-content privacy-policy">
          {/*<CommonPageBanner*/}
          {/*  title={pageData.banner_title.text}*/}
          {/*  backgroundUrl={pageData.banner_background.url}*/}
          {/*  subtitle={pageData.banner_subtitle.text}*/}
          {/*  description={pageData.banner_description.html}*/}
          {/*/>*/}
          <div className="container-fluid inside-content-row">
            <div className="row">
              <div className="col-md-12">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      {parse(pageData.page_content.text)}
                    </div>
                    <div className="col-md-12">
                      <Accordion
                        className='privacy-collapse'
                        defaultActiveKey={["0"]}
                        activeKey={activeKey}
                        key={"0"}
                        flush
                        alwaysOpen
                        onSelect={handleAccordionToggle}
                      >
                        <div className='privacy-collapse__list'>
                          {
                            pageData?.collapse_item?.map((element, index) => {
                              const itemTitle = element?.item_title
                              const itemID = element?.item_id
                              const sectionHeader = element?.section_header
                              const sectionContent = parse(element?.section_content?.text || '')
                              const itemTitleClassName = element?.item_title?.toLowerCase().replace(/\s+/g, '-');
                              const itemContent = parse(element?.item_content?.text || '')
                              return(
                                <div
                                  className={`privacy-collapse__list-item privacy-collapse__list-item--${itemTitleClassName}`}
                                  key={"accord-item-" + index}
                                  >
                                  {itemID && <div id={itemID} className='privacy-collapse__anchor'></div>}
                                  <div id={'section-' + (index + 1)} className='privacy-collapse__anchor'></div>
                                  {sectionHeader && <h2>{sectionHeader}</h2>}
                                  {sectionContent && <p>{sectionContent}</p>}
                                  {
                                    itemTitle&&
                                    <Accordion.Item eventKey={index} className={"privacy-collapse__toggle"}>
                                      <Accordion.Header as='h3' className='privacy-collapse__header'>
                                        <FontAwesomeIcon icon={faMinus} className='privacy-collapse-icon-minus' aria-hidden="false" size="lg" />
                                        <FontAwesomeIcon icon={faPlus} className='privacy-collapse-icon-plus' aria-hidden="false" size="lg" />
                                        <span>{itemTitle}</span>
                                      </Accordion.Header>
                                      <Accordion.Body key={"accord-body-"+index} className='privacy-collapse__body'>
                                        {itemContent}
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  }
                                </div>
                              )
                            })
                          }
                        </div>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
}

export const query = graphql`
query PrivacyPolicyPageQuery($uid: String, $id: String, $lang: String){
    prismicPrivacyPolicyPage(uid: { eq: $uid }, id: { eq: $id }, lang: { eq: $lang }) {
        _previewable
        url
        uid
        type
        id
        lang
        alternate_languages {
          id
          type
          lang
          uid
        }
        data {
            banner_background {
                alt
                url
            }
            banner_description {
                text
            }
            banner_subtitle {
                text
            }
            banner_title {
                text
            }
            content_header {
                text
            }
            social_card {
                url
            }
            meta_description {
                text
            }
            meta_title {
                text
            }
            page_content {
                html
                text
            }
            collapse_item {
              section_header
              section_content {
                html
                text
              }
              item_id
              item_title
              item_content {
                html
                text
              }
            }
        }
    }
}
`
export default withPrismicPreview(PrivacyPolicy)