// core
import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// form validation
import { Formik, Field, Form } from "formik"

// assets
import { validateDobField } from "../assets/js";

//constants
import { SUBSCRIPTION_FORM, SUBSCRIPTION_SIGN_UP_BASE_URL, USA_STATES_LIST} from "../constants";
import { VALIDATION_EN, VALIDATION_ES } from "../constants/formValidation"

// schemas
import {
    newsLettersFormValidationSchema,
    newsLettersFormValidationSchemaWithoutState,
    newsLettersFormValidationSchema_es,
    newsLettersFormValidationSchemaWithoutState_es
} from '../schemas'

// icons
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

// data layer
import { setSubscriptionSignUpEvent } from '../assets/js/data-layer'
import { useConsent } from '../components/ConsentContext'

const NewsLetterSignUp = ({ subscriptionFormTitle, subscriptionFormCopy, subscriptionFormName, submitBtnText = 'JOIN US', activeCampaignTag, lang = 'en-us' }) => {
    const [showForm, setShowForm] = useState(true)
    const [failedMsg, setFailedMsg] = useState(false)
    const [birthDayValue, setBirthDayValue] = useState("")
    const [parmsValue, setParamsValue] = useState("")
    const [usaStates, setUsaStates]=useState([])
    const consentAccepted = useConsent()
    const validations = lang === "en-us" ? VALIDATION_EN : VALIDATION_ES

    const initialValues = {
        firstname: "",
        lastname: "",
        email: "",
        birthdate: "",
        state: "",
        agreeCheckbox: false
    }

    const formLocation = {
        footer : subscriptionFormName === SUBSCRIPTION_FORM.FOOTER,
        popUp: subscriptionFormName === SUBSCRIPTION_FORM.POP_UP,
        subscriptionPage: subscriptionFormName === SUBSCRIPTION_FORM.SUBSCRIBE_PAGE
    }

    useEffect(()=>{
        const options = Object.keys(USA_STATES_LIST)
        setUsaStates(options)
    }, [])


    const handleBirthDateChange = (e) => {
        const output = validateDobField(e)
        setBirthDayValue(output.join('').substr(0, 10))
        setParamsValue(e.target.value)
    }

    let validationSchema
    if(lang === 'en-us'){
        validationSchema = subscriptionFormName === SUBSCRIPTION_FORM.POP_UP? newsLettersFormValidationSchemaWithoutState(parmsValue) : newsLettersFormValidationSchema(parmsValue)
    }else{
        validationSchema = subscriptionFormName === SUBSCRIPTION_FORM.POP_UP? newsLettersFormValidationSchemaWithoutState_es(parmsValue) : newsLettersFormValidationSchema_es(parmsValue)
    }

    const preventNumbersAndSpecialChars = (event) => {
        const input = event.target;
        let value = input.value;
        value = value.replace(/[^a-zA-Z\s]/g, '');
        if (value.startsWith(' ')) {
          value = value.substring(1);
        }
        return value
    };
    return (
        <>
            {/* FORM */}
            { showForm && <Formik
                initialValues={initialValues}
                validationSchema={ validationSchema }
                onSubmit = {(values, { setSubmitting, resetForm }) => {
                    setSubmitting(false)
                    // TODO segment ID
                    const data = {
                        ListID: 239,
                        Key: 'rgkx5ee6zl',
                        FirstName: values.firstname.trim(),
                        LastName: values.lastname.trim(),
                        Email: values.email.trim(),
                        birthdate: values.birthdate.trim(),
                        State: values.state,
                        Tag: activeCampaignTag,
                        Consent: values.agreeCheckbox.toString()
                    }

                    axios({
                        method: "POST",
                        url: SUBSCRIPTION_SIGN_UP_BASE_URL.PROD,
                        data: JSON.stringify(data),
                        cache: false,
                        headers: {
                            "Content-Type": "application/json; charset=utf-8"
                        }
                    }).then(function (response) {
                        setSubscriptionSignUpEvent(subscriptionFormName, consentAccepted)
                        // console.log('response  ', response)
                        // Update the form to Thank you message
                        setShowForm(false)
                    }).catch(function () {
                        // handle error
                        // console.log("Newsletter subscription form submission has failed.")
                        setShowForm(false)
                        setFailedMsg(true)
                    })
                    // Reset form
                    resetForm()
                }}
            >
                {({ values, errors, submitCount,setFieldValue }) => {
                    return (
                        <div className="subscription-form">

                            <h1 className="subscription-form-title">{subscriptionFormTitle}</h1>
                            { subscriptionFormCopy && <p className="subscription-form-description">{subscriptionFormCopy}</p> }


                            <div className="subscribe-form-wrapper">

                                <Form className="newsletter-form" autoComplete="off">

                                    {/* First Name */}
                                    <div className="position-relative">
                                        <Field type="text"
                                               id="firstname"
                                               name="firstname"
                                               className="w-100 newsletter-input"
                                               placeholder={validations.signupFormFirstName}
                                               onChange={(event) => {
                                                const firstNameValue=preventNumbersAndSpecialChars(event);
                                                setFieldValue("firstname",firstNameValue)
                                               }}
                                        />

                                        { submitCount > 0 && errors.firstname && <span className="input-error">{errors.firstname}</span> }
                                    </div>

                                    {/* Last Name */}
                                    <div className="position-relative">
                                        <Field type="text"
                                               name="lastname"
                                               id="lastname"
                                               className="w-100 newsletter-input"
                                               placeholder={validations.signupFormLastName}
                                               onChange={(event) => {
                                                const lastNameValue=preventNumbersAndSpecialChars(event);
                                                setFieldValue("lastname",lastNameValue)
                                               }}
                                        />

                                        { submitCount > 0 && errors.lastname && <span className="input-error">{errors.lastname}</span> }
                                    </div>

                                    {/* DOB */}
                                    <div className="position-relative">

                                        <Field type="text"
                                               name="birthdate"
                                               className="w-100 newsletter-input"
                                               id="birthdate"
                                               placeholder={validations.signupFormDOB_A}
                                               onChange={handleBirthDateChange}
                                               onFocus={(e)=> e.target.placeholder = validations.signupFormDOB_B}
                                               onBlur={(e)=> e.target.placeholder = validations.signupFormDOB_A}
                                               value={(values.birthdate = birthDayValue)}
                                        />

                                        { submitCount > 0 && errors.birthdate && <span className="input-error ">{errors.birthdate}</span> }
                                    </div>

                                    {/* Email */}
                                    <div className="position-relative">

                                        <Field type="text"
                                               name="email"
                                               className="w-100 newsletter-input"
                                               id="email"
                                               placeholder={validations.signupFormEmail}
                                        />

                                        { submitCount > 0 && errors.email && <span className="input-error">{errors.email}</span> }
                                    </div>

                                    {/* State */}
                                    <div className='position-relative'>
                                        <Field
                                            className="w-100 newsletter-input"
                                            as="select"
                                            name="state"
                                            placeholder={subscriptionFormName === SUBSCRIPTION_FORM.POP_UP? validations.signupFormState:`${validations.signupFormState}*`}
                                        >
                                            <option value="">{subscriptionFormName === SUBSCRIPTION_FORM.POP_UP? validations.signupFormState:`${validations.signupFormState}*`}</option>
                                            {usaStates?.length && usaStates.map((state) => { return <option key={state} value={state}>{ state }</option>}) }
                                        </Field>
                                        <FontAwesomeIcon icon={faChevronDown} className="fa-solid arrow-icon" />

                                        { submitCount > 0 && errors.state && <span className="input-error">{errors.state}</span> }
                                    </div>

                                    {/* Agree Checkbox */}
                                    <div className="agree-checkbox position-relative">
                                        <label className="agree-checkbox__label">
                                            <Field id="agreeCheckbox" type="checkbox" name="agreeCheckbox" />
                                            &nbsp;
                                            <span className="agree-checkbox__checkmark"></span>
                                            &nbsp;
                                            <span className="agree-checkbox__copy" dangerouslySetInnerHTML={{ __html: validations.signupTickBoxHtml}} />
                                        </label>
                                        { submitCount > 0 && errors.agreeCheckbox && <span className="input-error">{errors.agreeCheckbox}</span> }
                                    </div>

                                    {/* submit button */}
                                    <input className="newsletter-input-submit" type="submit" value={submitBtnText} />
                                </Form>
                            </div>
                        </div>
                    )
                }}
            </Formik> }

            {/* MESSAGE */}
            { !showForm &&
                <div className="subscription-form" data-thank-you-msg="true">
                    <div id="subscription-form-title" className="subscription-form-title text-center text-bold"> {subscriptionFormTitle} </div>
                    <div className="newsletter-form-message-wrapper">
                        { failedMsg && <div className="text-center newsletter-form-message">{validations.signupFormErrorMsg}</div> }
                        { !failedMsg && <div className="text-center newsletter-form-message">{validations.signupFormSuccessMsg}</div> }
                    </div>
                </div>
            }
        </>
    )
}

export default NewsLetterSignUp