// core
import React from "react";
import { Link } from "gatsby";

export const AllFlavorsVP = ({ slice }) => {
  const data = slice.primary;
  const items = slice.items;

  // console.log('all flav items', slice)

  return (
    <>
    <div className="product-all-flavors">
      <h2 className="product-all-flavors__header">{data.header.text || ''}</h2>
      <div className="product-all-flavors__list">
          {items.map((element, index) => {
            const linkUrl = element.item_link.document?.url
            const productImageSrc = element.item_link.document?.data.product_image?.url
            const productImageAlt = element.item_link.document?.data.product_image?.alt ? element.item_link.document.data.product_image?.alt : element.item_link.document?.data.product_name?.text || ''
            const productName = element.item_link.document?.data.product_name.text || ''
            const tag = element.item_link.document?.data.product_category.document?.data.category_new_text || element.item_link.document?.data.product_new_text || null

          return (
              <a key={index} href={linkUrl} className="product-all-flavors__item">
                <img className="product-all-flavors__item-image" src={productImageSrc} alt={productImageAlt} />
                <span className="product-all-flavors__item-name">{productName}</span>
                <span className="product-all-flavors__item-tag">{tag}</span>
              </a>
          );
          })}
      </div>
    </div>
    </>
  );
};
