import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import { useCookies } from 'react-cookie'

//preview
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'
import { unpublishedRepositoryConfigs } from '../../utils/prismicUnpublishedPreview'

//components
import Seo from "../../components/Seo";
import Layout from "../../components/Layout";

//assets
import goldLogo from '../../assets/images/gold-logo-shadow.webp'

const NotFoundPage = ({ data }) => {
  const pageContent = data.prismicErrorPage
  const pageData = data.prismicErrorPage.data
  const [cookies] = useCookies(['wcUserURL'])
  const [lang, setLang] = useState('es-mx')

  const { type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    if(isBrowser) {
      const prevURL = cookies['wcUserURL']
      const currentURL = window.location.href
      const urlToCheck = prevURL || currentURL
      setLang(urlToCheck?.includes('/es/') ? 'es-mx' : 'en-us')
    }
  }, [isBrowser, cookies])

  return (
    <Layout currentPage={"error"} activeDocMeta={activeDoc}>
      <Seo
          title={pageData.meta_title?.text }
          description={pageData.meta_description?.text}
      />
      <section className="error">
        <img src={goldLogo} className="error__image" alt="White Claw Zero US Logo"/>
        <h1 className='error__header'>ERROR 404</h1>
        <p className='error__description'>Page could not be found</p>
        <Link to={"/"} _target="self" className="button-style-2 mt-5">
          Back to Home
        </Link>
      </section>
    </Layout>
  );
};

export default withPrismicUnpublishedPreview(
  NotFoundPage,
  unpublishedRepositoryConfigs,
)

export const query = graphql`
  query ErrorPageQuery($id: String, $lang: String) {
    prismicErrorPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
      }
      data {
        meta_description {
          text
        }
        meta_title {
          text
        }
      }
    }
  }
`
