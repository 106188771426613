const NUTRITIONAL_TABLE_TEXT_EN ={
  description: 'Not a significant source of total fat, saturated fat, trans fat, cholesterol,dietary fiber, protein, vitamin D, calcium, iron and potassium.',
  dailyValue: `* The % Daily Value tells you how much a nutrient in a serving of food contributes to a daily diet. 2,000 calories a day is used for general  nutrition advice.`,
  amountPerServing : 'Amount per Serving',
  dailyValuePercentage: '% Daily Values*',
  ingredients: 'Ingredients',

  fat: 'Total Fat',
  saturated : 'Saturated Fat',
  polyunsaturated: 'Polyunsaturated Fat',
  monounsaturated: 'Monounsaturated Fat',
  cholesterol: 'Cholesterol',
  sodium: 'Sodium',
  carbohydrate: 'Total Carbohydrate',
  fiber: 'Dietary Fibre',
  sugars: 'Sugars',
  protein: 'Protein',
}

const NUTRITIONAL_TABLE_TEXT_ES ={
  description: 'Not a significant source of total fat, saturated fat, trans fat, cholesterol,dietary fiber, protein, vitamin D, calcium, iron and potassium. - Nutritional information',
  dailyValue: 'El % del valor diario (VD) indica cuánto aporta un nutriente en una porción de alimentos a la dieta diaria. Como consejo nutricional general, se utilizan 2000 calorías al día.',
  amountPerServing : 'Cantidad por ración',
  dailyValuePercentage: '% Valores diarios',
  ingredients: 'Ingredientes',

  fat: 'Grasa total',
  saturated : 'Grasas saturadas',
  polyunsaturated: 'Grasas poliinsaturadas',
  monounsaturated: 'Grasa monoinsaturada',
  cholesterol: 'Colesterol',
  sodium: 'Sodio',
  carbohydrate: 'Carbohidratos totales',
  fiber: 'Fibra dietética',
  sugars: 'Azúcares',
  protein: 'Proteína',
}

export { NUTRITIONAL_TABLE_TEXT_EN, NUTRITIONAL_TABLE_TEXT_ES }