import React, { useState, useEffect } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCookies } from 'react-cookie'


// components
import CountrySelector from './CountrySelector';
import { LanguageSwitcher } from "./LanguageSwitcher";
import NewsLetterSignUp from "./NewsLetterSignUp";

// icons
import { faSquareInstagram, faSquareFacebook, faSquareXTwitter } from '@fortawesome/free-brands-svg-icons'

// assets
import globeWhite from '../assets/images/icons/globe-white.svg'

// preview
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

// constants
import {
  SUBSCRIPTION_FORM,
  SOCIAL_PLATFORM_URL,
  SOCIAL_PLATFORM,
  ACTIVE_CAMPAIGN_TAG,
  KETCHPREFS,
  LANG
} from "../constants";
import { VALIDATION_EN, VALIDATION_ES } from "../constants/formValidation"

// data-layer
import { setSocialIconClickedEvent } from "../assets/js/data-layer";
import { useConsent } from '../components/ConsentContext'
import instagramIcon from "../assets/images/icons/instagram.svg";
import CustomLink from "./CustomLink";

const Footer = ({disabledMenu, activeDocMeta}) => {
  const { lang } = activeDocMeta

  const queryData = useStaticQuery(graphql`
  {
    EN: prismicFooter(lang: { eq: "en-us" }) {
      id
      _previewable
      data {
        footer_legal {
          text
          richText
        }
        menu_links {
          label {
            text
          }
          link {
            id
            url
          }
          link_id
        }
      }
    }
    ES: prismicFooter(lang: { eq: "es-mx" }) {
      id
      _previewable
      data {
        footer_legal {
          text
          richText
        }
        menu_links {
          label {
            text
          }
          link {
            id
            url
          }
          link_id
        }
      }
    }
  }
  `)

  const validations = lang === "en-us" ? VALIDATION_EN : VALIDATION_ES
  const subscriptionFormTitle =validations.signupFormFooterTitle
  const subscriptionFormCopy = null
  const subscriptionFormName = SUBSCRIPTION_FORM.FOOTER
  const activeCampaignTag = ACTIVE_CAMPAIGN_TAG.FOOTER
  const submitBtnText = validations.signupFormFooterSubmitBtn
  const [cookies] = useCookies()
  const [persID, setPersID] = useState(false)

  const { data } = useMergePrismicPreviewData(queryData)
  const pageData = lang === LANG.EN ?  data.EN.data :  data.ES.data
  // console.log(pageData)


  const menuLinks = pageData.menu_links
  const consentAccepted = useConsent()

  const legalText = pageData.footer_legal.text
  // country select functions
  const [showCountrySelector, setShowCountrySelector] = useState(false)
  const closeCountrySelector = () => setShowCountrySelector(false)
  const openCountrySelector = () => setShowCountrySelector(true)

  const onSocialIconClick = (platform) => {
    const dl = {
      platform,
      location: 'footer'
    }
    setSocialIconClickedEvent(dl, consentAccepted)
  }

  useEffect(() => {
    if (!cookies?.persID) {
      return
    }
    setPersID(cookies.persID)
  }, [cookies?.persID])

  return (
      <footer className="us-site">

        <div className="row footer-country-selector text-white">
          {/* Country Selector */}
          <LanguageSwitcher activeDocMeta={activeDocMeta} />
        </div>

        <div className="container">
          <div className="row pb-0">
            <div className="col-12 footer-legal-column">
              <div className="row">
                <div className="col-12">
                  <Link to="/" title="White Claw" className="d-block mx-auto footer-logo"></Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-0">
            <div className="col-12">
              {/* Newsletter Signup */}
              <NewsLetterSignUp
                  subscriptionFormTitle={subscriptionFormTitle}
                  subscriptionFormCopy={subscriptionFormCopy}
                  subscriptionFormName={subscriptionFormName}
                  activeCampaignTag={activeCampaignTag}
                  submitBtnText={submitBtnText}
                  lang={lang}
              />
            </div>
          </div>

          <div className="row pb-0">
            <div className="col-12">
              {(disabledMenu) ? [] :
                  <ul className="list-unstyled d-flex flex-column flex-lg-row justify-content-center align-content-center footer-menu flex-wrap">
                    {
                      menuLinks.map((navItem, index) => {
                        const linkUrl = navItem.link.url
                        const linkID = navItem.link_id
                        const linkText = navItem.label.text
                        if(linkUrl){
                          return (
                            <li key={`link-${index}`} className="nav-item text-center web">
                              <CustomLink
                                  to={linkID === "faq" 
                                  ? linkUrl.includes('?') ? `${linkUrl}&persID=${persID}&adult=${cookies?.adult}&ag=${!cookies?.adult}` : `${linkUrl}?persID=${persID}&adult=${cookies?.adult}&ag=${!cookies?.adult}`
                                  : linkUrl}
                                  title={"White Claw " + (linkText)}
                              >
                                {linkText}
                              </CustomLink>
                            </li>
                        )
                        }else{
                          return(
                            <></>
                          )
                        }
                      
                      })
                    }
                    <li className="nav-item text-center web">
                      <a
                        href="#"
                        title={`White Claw Zero US ${lang === 'en-us' ? 'Privacy Preferences' : 'Preferencias de privacidad'}`}
                        onClick={(e) => {
                          e.preventDefault()
                          window.ketch('showPreferences', KETCHPREFS)
                        }}
                      >
                        {lang === 'en-us' ? 'Privacy Preferences' : 'Preferencias de privacidad'}
                      </a>
                    </li>
                  </ul>
              }
            </div>
          </div>

          <div className="row py-3">
            <div className="col-12">
              <ul className="list-unstyled d-flex flex-lg-row justify-content-center align-content-center footer-menu mb-0">
                <li className="text-center">
                  <a href={ SOCIAL_PLATFORM_URL.INSTAGRAM } target="_blank" rel='noopener noreferrer' className='social-icon'
                     onClick={()=>onSocialIconClick(SOCIAL_PLATFORM.INSTAGRAM)}
                  >
                    <img src={instagramIcon} alt="Instagram Icon" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row py-0">
            <div className="col-12">
              <div className="copyright-blurb py-0">
                <p className="mx-0 my-0 px-0 py-3 text-center">
                  {legalText}
                </p>
              </div>
            </div>
          </div>
        </div>
       
      </footer>
  )
}

export default Footer