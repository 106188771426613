// core
import React from "react";
import { Link } from "gatsby";

// data-layer
import { setProductFlavorClickedEvent } from "../../assets/js/data-layer";
import { useConsent } from '../../components/ConsentContext'

export const AllFlavors = ({ slice }) => {
  const data = slice.primary;
  const dlCatName = slice.primary.category.document?.data.taxonomy_product_category || "";
  // console.log('all flav slice.primary', data)
  const consentAccepted = useConsent()

  const title = data.header.text || `${data.category.document.data.category_name_prefix} ${data.category.document.data.category_name} ${data.category.document.data.category_name_suffix}`
  const categoryItems = data.category.document?.data.category_item || []

  return (
      <div className="product-all-flavors">
        <h2 className="product-all-flavors__header">{ title }</h2>
        <div className="product-all-flavors__list">
          { categoryItems.map((element, index) => { // eslint-disable-line array-callback-return

            const url = element.category_item_link.document?.url || ''
            const imgSrc = element.category_item_link.document?.data.product_image.url || ''
            const imgAlt = element.category_item_link.document?.data?.product_image.alt ? element.category_item_link.document.data?.product_image.alt : element.category_item_link.document?.data?.product_name?.text
            const productName = element.category_item_link.document?.data.product_name.text || ''
            const tag = data.category.document?.data.category_new_text || element.category_item_link.document?.data.product_new_text || null
            const size = element.category_item_link.document?.data.taxonomy_product_size || ''
            const flavor = element.category_item_link.document?.data.taxonomy_product_flavor || ''

            return (
                <a
                  key={index}
                  href={url}
                  className="product-all-flavors__item"
                  onClick={()=>{
                    const dl = {
                      flavor,
                      category: dlCatName.toLowerCase(),
                      position: index + 1,
                      url: url,
                      location: 'product details page',
                      size
                    }
                    setProductFlavorClickedEvent(dl, consentAccepted)
                  }}
                  >
                  <img className="product-all-flavors__item-image" src={imgSrc} alt={imgAlt}/>
                  <span className="product-all-flavors__item-name">{ productName }</span>
                  <span className="product-all-flavors__item-tag">{ tag }</span>
                </a>
            );
          })}
        </div>
      </div>
  );
};