// core
import React, { useEffect, useState, useRef, createRef } from "react"
import { Link as AnchorLink, scroller } from "react-scroll";
import { useLocation } from '@reach/router';
import Accordion from "react-bootstrap/Accordion"
import { PrismicRichText } from '@prismicio/react'

// components
import ImgWithFallback from "../../components/ImgWithFallback"
import BVInlineRating from "./BVInlineRating"

// data-layer
import { setProductFlavorClickedEvent, setCategoryViewedEvent } from "../../assets/js/data-layer"
import { useConsent } from '../../components/ConsentContext'

const AccordionSection = ({currentBody} ) => {
  const location = useLocation()
  const isBrowser = typeof window !== "undefined"

  const [baseURL, setBaseURL] = useState(location?.href)
  const elemRefs = useRef([])

  const consentAccepted = useConsent()

  // taxonomy
  // const flavor = item.category_item_link.document.data.taxonomy_product_flavor
  // const size = item.category_item_link.document.data.taxonomy_product_size
  // const category = element.primary.accordion_header_title.text.toLowerCase()


  elemRefs.current = currentBody.map((element, i) => {
    return (
        elemRefs.current[i] ?? createRef()
    )
  })

  const handleTitleGroup = (elm) => {
    const itemAnchor = `#${elm.current.parentNode.querySelector('.products-anchor').id}`
    const anchorURL = baseURL + itemAnchor
    window.location.href = anchorURL
  }


  useEffect(() => {
    if (!isBrowser || !currentBody?.length) {
      return
    }
    if (isBrowser) {
      if (baseURL.includes('#')) {
        const splitURL = baseURL.split('#')

        // remove utm from pathname
        const splitPathName = splitURL[1].split("?")
        setBaseURL(splitURL[0])

        if (currentBody?.length && splitPathName[0]){

          document.querySelector(`.anchor-toggle_${splitPathName[0]} .accordion-button`).click()
          scroller.scrollTo(`${splitPathName[0]}`, {
            duration: 1000,
            delay: 50,
            smooth: true,
          });
        }
      } else {
        // open all accordion items by default
        document.querySelectorAll(`.accordion-collapse`).forEach(accItem => accItem.classList.add('show'))

        setBaseURL(location.href)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBrowser, currentBody])

  return <Accordion defaultActiveKey={["0"]} alwaysOpen key={"accord-parent"}>
    {
      currentBody.map((element, index) => {
        const catNewText = element.primary.accordion_category.document?.data.category_new_text || ''
        const catItem = element.primary.accordion_category.document?.data.category_item || []
        const includePacks = element.primary.accordion_include_packs
        const category = element.primary.accordion_header_title.text.toLowerCase()
        const rtbTitles = element.primary.rtb_title?.richText || ''
        const rtbSubTitles = element.primary.rtb_subtitle?.richText || ''
        const headerBgImage = element.primary.accordion_header_bg_image_png?.url
        const anchorName = element.primary.anchor_name
        const currentRef = elemRefs.current[index]
        const headerPromoTitle = element.primary.accordion_header_promo_title?.text || ''
        const headerTitle = element.primary.accordion_header_title?.richText || ''
        const headerBlurb = element.primary.accordion_header_blurb?.text || ''
        const productImage = {
          url: element.primary.accordion_header_product_image_png.url,
          alt: element.primary.accordion_header_product_image_png.alt
        }

        const newLabelText = catNewText || headerPromoTitle

        return (
            <Accordion.Item eventKey={index} key={"accord-item-" + index} className={`anchor-toggle_${anchorName}`}>

              <Accordion.Header className="w-100">

                <div className="row prod-accor-cont" onClick={() => { 
                    handleTitleGroup(currentRef) 

                    const dl = { category }
                  
                    // data-layer
                    setCategoryViewedEvent(dl, consentAccepted)
                  }}>
                  <div className="col-md-12">
                    <div className={"row prod-accor-cont-hd " + element.primary.class_name} style={ headerBgImage && {backgroundImage: `url(${headerBgImage})`}}>
                      <div className="col-12 d-flex py-0 px-0">
                        <div className="d-flex justify-content-center w-100 position-relative">
                          <AnchorLink id={element.primary.anchor_name} to={element.primary.anchor_name} name={element.primary.anchor_name} className="products-anchor"></AnchorLink>
                          <div className="align-self-center position-absolute __title-group-parent" ref={currentRef}>
                            <div className="d-flex flex-column __title-group">
                              { newLabelText && <span className="align-self-center mb-2 __title-1">{ newLabelText }</span> }
                              <span className="align-self-center mb-1 __title-2"><PrismicRichText field={headerTitle}/></span>
                              <span className="align-self-center __title-3">{headerBlurb}</span>
                            </div>
                          </div>

                          <div className={"prod-accor-cont-hd--img position-absolute " + (index % 2 === 0 ? "position-absolute-right-aligned" : "position-absolute-left-aligned")}>
                            <ImgWithFallback
                                classNamePicture={""}
                                classNameImg={"img-fluid"}
                                src={productImage.url}
                                fallbackSrc={productImage.url}
                                alt={productImage.alt}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Header>

              <Accordion.Body key={"accord-body_" + index}>
                <div className="row prod-accor-cont">
                  <div className="col-12">
                    <div className="row accordion-row-body">
                      <div className="col-12 text-center accordion-info-field">
                        <div className='d-flex justify-content-center flex-wrap'>
                          { rtbTitles?.length !== 0 && rtbTitles.map((row, index) => {
                            return  <span key={row.text} className='accordion-info-field__rtb-header-wrap'>
                                <span className="accordion-info-field__rtb-header-text">{row.text}</span>
                              { rtbSubTitles[index] && <span className='accordion-info-field__rtb-header-subtext'>{rtbSubTitles[index].text}</span> }
                              </span>
                          })}
                        </div>
                      </div>
                      {
                        catItem.map((item, index) => {
                          const catItemURL = item.category_item_link.document?.url || ''
                          const catItemName = item.category_item_link.document?.data.product_name.richText || []
                          const catItemImage = item.category_item_link.document?.data.product_image || ''
                          const catItemNewText = catNewText ? catNewText : item.category_item_link.document?.data.product_new_text || ''
                          const catItemType = item.category_item_link.document?.data.product_type || ''
                          const flavor = item.category_item_link.document?.data.taxonomy_product_flavor || ''
                          const size = item.category_item_link.document?.data.taxonomy_product_size || ''
                          const productId = item.category_item_link.document?.data?.product_id || ''
                          const productIdBV = productId.split(",").splice(0, 1).join("")

                          if(catItemType !== "Pack") {
                            return (
                                <div className="col-6 col-md-4 col-lg-3 mb-5" key={"item" + index}>
                                  <div className="d-flex h-100 flex-column align-items-end text-center mx-auto">
                                    <a
                                        href={catItemURL}
                                        className="mx-auto mt-auto mb-auto align-self-center"
                                        onClick={() => {

                                          // data-layer
                                          const dl = {
                                            flavor,
                                            category,
                                            position: index + 1,
                                            url: catItemURL,
                                            location: 'products page',
                                            size
                                          }
                                          setProductFlavorClickedEvent(dl, consentAccepted)
                                        }}
                                    >
                                      <img
                                          className={"img-fluid product-image mx-auto"}
                                          src={catItemImage && catItemImage.url}
                                          alt={catItemImage && catItemImage.alt}
                                      />
                                    </a>
                                    <span className="mx-auto mt-4 align-self-end product-title">
                                      {catItemName.map((item, i) => {
                                        return  <div key={`${item.text + i}`}><PrismicRichText field={[item]} /></div>
                                      })}
                                      <BVInlineRating id={productIdBV} url={catItemURL}/>
                                      { catItemNewText && <span className="d-block"><span className="mx-auto mt-2 align-self-end product-new-item">{catItemNewText}</span></span> }
                                      </span>
                                  </div>
                                </div>
                            )
                          }
                        })}
                      {catItem.map((item, index) => {
                        const catItemURL = item.category_item_link.document.url
                        const catItemName = item.category_item_link.document.data.product_name.richText
                        const catItemImage = item.category_item_link.document.data.product_image
                        const catItemNewText = catNewText ? catNewText : item.category_item_link.document.data.product_new_text
                        const catItemType = item.category_item_link.document.data.product_type
                        const flavor = item.category_item_link.document.data.taxonomy_product_flavor
                        const size = item.category_item_link.document.data.taxonomy_product_size
                        const productId = item.category_item_link.document.data?.product_id || ''
                        const productIdBV = productId.split(",").splice(0, 1).join("")

                        if(includePacks && catItemType === "Pack") {
                          return (
                              <div className="col-6 col-md-4 col-lg-3 mb-5" key={"item" + index}>
                                <div className="d-flex h-100 flex-column align-items-end text-center mx-auto">
                                  <a
                                      href={catItemURL}
                                      className="mx-auto mt-auto mb-auto align-self-center"
                                      onClick={() => {

                                        // data-layer
                                        const dl = {
                                          flavor,
                                          category,
                                          position: index + 1,
                                          url: catItemURL,
                                          location: 'products page',
                                          size
                                        }
                                        setProductFlavorClickedEvent(dl, consentAccepted)
                                      }}
                                  >
                                    <img
                                        className={"img-fluid product-image mx-auto"}
                                        src={catItemImage.url}
                                        alt={catItemImage.alt}
                                    />
                                  </a>
                                  <span className="mx-auto mt-4 align-self-end product-title">
                                    {catItemName.map((item, i) => {
                                      return <div key={catItemURL}><PrismicRichText field={[item]} /></div>
                                    })}
                                    <BVInlineRating id={productIdBV} url={catItemURL}/>
                                    { catItemNewText && <span className="d-block"><span className="mx-auto mt-2 align-self-end product-new-item">{catItemNewText}</span></span> }
                                    </span>
                                </div>
                              </div>
                          )}
                      })
                      }
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
        )
      })
    }
  </Accordion>
}

export default AccordionSection