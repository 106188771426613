const AGE_GATE_VALIDATION_EN = {
  invalidMonth: "Month must be between 1 and 12",
  monthRequired: "Month is required",
  invalidDay: "Invalid day for selected month",
  dayRequired: "Day is required",
  invalidAge: "Please come back and visit us when you're 21.",
  yearRequired: "Year is required",
  submitBtn: "Confirm",
  isValidYear :"Must be exactly 4 digits",

  title: 'WHAT IS YOUR DATE OF BIRTH?',
  countryName: 'UNITED STATES',
  countryLang: 'English',
  legalCopy: 'All Registered Trademarks, used under license by White Claw Seltzer Works, Chicago, IL'
}

const AGE_GATE_VALIDATION_ES = {
  invalidMonth: "El mes debe estar entre 1 y 12",
  monthRequired: "Se requiere el mes",
  invalidDay: "Día no válido para el mes seleccionado",
  dayRequired: "Se requiere el día",
  invalidAge: "Lo sentimos, debes tener 21+ años para entrar.",
  yearRequired: "Se requiere el año",
  submitBtn: "Confirmar",
  isValidYear :"Debe tener exactemente cuatro caractereres",

  title: '¿CUÁL ES TU FECHA DE NACIMIENTO?',
  countryName: 'ESTADOS UNIDOS',
  countryLang: 'Español',
  legalCopy: 'Todas las marcas registradas, son utilizadas bajo licencia por White Claw Seltzer Works, Chicago, IL'
}


export { AGE_GATE_VALIDATION_EN, AGE_GATE_VALIDATION_ES }