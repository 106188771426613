import axios from 'axios'

// constants
import { GOOGLE_MAP_API_URL } from "../../constants/baseUrl"

export const getAddressFromZipCode = async (zipcode) => {
    try{
        const response = await axios({
            url: GOOGLE_MAP_API_URL('AIzaSyB14cC5KRuDXStxieEY6zbN_NdxKLLMaeg', zipcode),
            method: 'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        })

        if(response.status == 200){
            const result = response.data.results[0]
            if(!result){
                throw new Error('No address found.')
            }

            return result.address_components.reduce((cus, component) => {
                const types = component.types

                if(types.includes('postal_code')){
                    cus.zc = component.long_name
                }else if(types.includes('locality')){
                    cus.cty = component.long_name
                }else if(types.includes('country')){
                    cus.ctry = component.short_name
                }else if(types.includes('administrative_area_level_1')){
                    cus.st = component.long_name
                }
                return cus
            }, { addr: result.formatted_address })
        }else{
          throw new Error(`Error: ${response.status}, ${response.statusText}`)
        }
    }catch(err){
        console.error(err.message)
        return null
    }
}