// core
import React, {useEffect, useState} from "react";
import { PrismicRichText } from "@prismicio/react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";

// resources
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

// data-layer
import { setProductFlavorClickedEvent } from "../assets/js/data-layer";
import { useConsent } from '../components/ConsentContext'

//assets
import useWindowSize from "../hooks/useWindowSize";

// import Swiper core and required modules
import { Navigation } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

export const ProductCarousel = ({ slice }) => {
  const location = useLocation()
  const consentAccepted = useConsent()
  const { width } = useWindowSize()
  const [productList , setProductList] = useState([])
  const isHP = location.pathname === '/'

  // console.log("slice data ", slice);
  const dlCatName = slice.primary.category.document?.data.taxonomy_product_category || "";
  const catNewText = slice.primary.category.document?.data.category_new_text;
  const categoryItem = slice.primary.category.document?.data.category_item || "";
  const bgImageURL = slice.primary.background_image.url || "";
  const bgImageAlt = slice.primary.background_image.alt || "";

  const navigation = {
    nextEl: ".swiper-next-custom",
    prevEl: ".swiper-prev-custom",
  };

  const swiperBreakPoints = {
    0: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 3,
    },
    1200: {
      slidesPerView: 4,
    },
  };

  useEffect(()=>{

    if(!width || categoryItem === ""){
      return
    }

    if( width < 768 ){
      const newArray = [...categoryItem]
      const lastElement = newArray.splice(0, 1)[0];
      newArray.push(lastElement);
      setProductList(newArray)
    } else {
      setProductList(categoryItem)
    }


  },[width, categoryItem])

  if(productList.length === 0) return(<></>)

  return (
    <section className="product-carousel">
      <div className="product-carousel__body">
        <div className="product-carousel__bg-image">
          <img src={bgImageURL} alt={bgImageAlt} />
        </div>
        <Swiper modules={[Navigation]} navigation={navigation} initialSlide={0} speed={500} breakpoints={swiperBreakPoints}>
          {productList.map((item, index) => {
            const productItem = item.category_item_link.document.data || "";
            const prodItemURL = item.category_item_link.document.url || "";
            const prodItemImg = productItem.product_image.url || "";
            const prodItemImgAlt = productItem.product_image.alt || "";
            const prodItemName = productItem.product_name.richText || "";
            const prodItemNewText = catNewText ? catNewText : productItem.product_new_text;
            const prodItemType = productItem.product_type || "";
            const flavor =  productItem.taxonomy_product_flavor || "";
            const size =  productItem.taxonomy_product_size || "";
            const currentURL = location.href || "";

            return (
              prodItemType !== "Pack" && (
                <SwiperSlide key={index}>
                  <a
                    className={`product-carousel__link${prodItemNewText ? " product-carousel__link--new" : ""}`} 
                    href={prodItemURL}
                    onClick={(e) =>{
                      // data-layer
                      const dl = {
                        flavor,
                        category: dlCatName.toLowerCase(),
                        position: index + 1,
                        url: prodItemURL,
                        location: currentURL,
                        size
                      }
                      setProductFlavorClickedEvent(dl, consentAccepted)
                    }}
                    >
                    {prodItemImg && (
                      <div className="product-carousel__image">
                        <img src={prodItemImg} alt={prodItemImgAlt} />
                      </div>
                    )}

                    <div className="product-carousel__name">
                      {prodItemName.map((item, i) => {
                        return <div className={`product-carousel__name-text ${i === 2 ? 'smaller' : ''}`} key={item.text}>
                            <PrismicRichText field={[item]} />
                          </div>
                      })}
                      {(prodItemNewText ? <div className="product-carousel__name-new">{prodItemNewText}</div> : null)}
                      </div>


                  </a>
                </SwiperSlide>
              )
            );
          })}
        </Swiper>
        <button className="swiper-prev-custom">
          <span className="visually-hidden">Prev</span>
          <FontAwesomeIcon icon={faChevronLeft} className="fa-solid" size="2xl" />
        </button>
        <button className="swiper-next-custom">
          <span className="visually-hidden">Next</span>
          <FontAwesomeIcon icon={faChevronRight} className="fa-solid" size="2xl" />
        </button>
      </div>
    </section>
  );
};
