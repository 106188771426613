// core
import React, { useState } from 'react'

// form validation
import { Field } from "formik"

// components
import FieldErrorMessage from './FieldErrorMessage'
import WindowModal from './WindowModal'

// resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import {Link} from 'gatsby'
import { LANG } from '../../constants'

const HiddenFields = ({ inquiryTypeID,
                        lang,
                        validations,
                        submitCount,
                        errors,
                        toggleExtraFieldsContainer,
                        extraFieldsBtnText,
                        handleBatchNumberChange,
                        batchNumberValue,
                        values,
                        extraFieldsContainer,
                        batchCharValidationMsg,
                        handleTimeStampChange,
                        timeStampValue,
                        handleLineNumberChange,
                        lineNumberValue,
                        lineNumberValidationMsg,
                        setFieldValue
                      }) => {

  const [showWindowModal, setShowWindowModal] = useState(false)
  const [windowModalType, setWindowModalType] = useState("")

  // Window Modal Func
  const closeWindowModal = () => setShowWindowModal(false)
  const openWindowModal = (e, windowModalType) => {
    e.preventDefault()
    setWindowModalType(windowModalType)
    setShowWindowModal(true)
  }

  const preventNumbersAndSpecialChars = (event) => {
    const input = event.target;
    let value = input.value;
    value = value.replace(/[^a-zA-Z\s]/g, '');
    if (value.startsWith(' ')) {
      value = value.substring(1);
    }
    return value
  };

  return (
      <>
        {/* InquiryType 100000003 - Quality Concern*/}
        <div className={"row hidden-block batch-code " + (inquiryTypeID === "100000003" ? "" : "toggle-hide")}>
          <div className='col-12 pt-3'>
            <p className="pb-4">
              {validations.qualityTxt01}
              &nbsp;
              {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
              <a href="#" onClick={(e) => openWindowModal(e, 'batchimage')}>
                {validations.viewImage}
              </a>.
            </p>
            <p className="pb-4">
              {validations.qualityTxt02}
            </p>
            <p className="form-btn-container pb-5">
              <button type="button" className="form-btn form-btn-long-text" onClick={(e) => openWindowModal(e, 'decipherbatchnumber')}>{validations.decipherBatch}</button>
            </p>
            {/* WindowModal Component Batch / Decipher */}
            <WindowModal
                showWindowModal={showWindowModal}
                closeWindowModal={closeWindowModal}
                windowModalType={windowModalType}
                lang={lang}
            />
          </div>

          <div className="col-12 form-group">
            {/* Extra Fields Checkbox */}
            <label className="btn btn-block extra-fields-checkbox">
              <Field
                  type="checkbox"
                  name="extraFieldsCheckbox"
                  id="extra-fields-checkbox"
                  className="visually-hidden"
                  onClick={toggleExtraFieldsContainer}
              />
              {extraFieldsBtnText}
            </label>

            {submitCount > 0 && errors.extraFieldsCheckbox !== "" ? (
                <FieldErrorMessage error={errors.extraFieldsCheckbox} />
            ) : null}
            {/* Eof extra fields checkbox */}
          </div>

          <div className="col-12 col-md-4 form-group">
            {/* Batch number */}
            <Field
                type="text"
                id="form-batchcode"
                className="form-control"
                name="batchNumber"
                maxLength={9}
                placeholder={validations.batchNumberDD}
                onChange={handleBatchNumberChange}
                value={(values.batchNumber = batchNumberValue)}
                disabled={extraFieldsContainer}
            />
            {submitCount > 0 && errors.batchNumber !== "" && values.batchNumber !== "N/A" ? (
                <FieldErrorMessage error={errors.batchNumber} />
            ) : null}

            {batchCharValidationMsg !== "" ? (
                <FieldErrorMessage error={batchCharValidationMsg} />
            ) : null}
            {/* Eof batch number */}
          </div>

          <div className="col-12 col sm-6 col-md-4 form-group">
            {/* Time Stamp */}
            <Field
                type="text"
                id="form-timeStamp"
                className="form-control"
                name="timeStamp"
                maxLength={5}
                placeholder={validations.timeStampDD}
                onChange={ handleTimeStampChange }
                value={(values.timeStamp = timeStampValue)}
                disabled={extraFieldsContainer}
            />

            {submitCount > 0 && errors.timeStamp !== "" && values.timeStamp !== "N/A" ? (
                <FieldErrorMessage error={errors.timeStamp} />
            ) : null}
            {/* Eof time stamp */}
          </div>

          <div className="col-12 col-sm-6 col-md-4 form-group">
            {/* Line number */}
            <Field
                type="text"
                id="form-lineNumber"
                className="form-control"
                name="lineNumber"
                maxLength={2}
                placeholder={validations.lineNumberDD}
                onChange={ handleLineNumberChange }
                value={(values.lineNumber = lineNumberValue)}
                disabled={extraFieldsContainer}
            />

            {submitCount > 0 && errors.lineNumber !== "" && values.lineNumber !== "N/A" ? (
                <FieldErrorMessage error={errors.lineNumber} />
            ) : null}

            {lineNumberValidationMsg !== "" ? (
                <FieldErrorMessage error={lineNumberValidationMsg} />
            ) : null}
            {/* Eof line number */}
          </div>

          {/* Extra Fields - If 'I don't have batch number is clicked' */}
          <div className={'col-12 col-md-4 form-group ' + (extraFieldsContainer? "" : "d-none")}>
            {/* Package type */}
            <label className="select">
              <Field
                  as="select"
                  name="packageType"
                  className="extra-fields-js form-control"
              >
                <option value="">{validations.selectPackageTypeDD}</option>
                <option value={validations.canDD} data-subject={validations.canDD}>
                  {validations.canDD}
                </option>

                <FontAwesomeIcon icon={faChevronDown} className="fa-solid" />
              </Field>

              {submitCount > 0 && errors.packageType !== "" ? (
                  <FieldErrorMessage error={errors.packageType} />
              ) : null}
            </label>
            {/* Eof package type */}
          </div>
          <div className={'col-12 col-md-4 form-group ' + (extraFieldsContainer? "" : "d-none")}>
            {/* Package Quantity */}
            <label className="select" htmlFor='packageQuantity'>
              <Field
                  as="select"
                  className="extra-fields-js form-control"
                  name="packageQuantity"
              >
                <option value="">{validations.packageQuantityDD}</option>
                <option value={validations.singleDD} data-subject={validations.singleDD}>
                  {validations.singleDD}
                </option>
                <option value={validations.sixPackDD} data-subject={validations.sixPackDD}>
                  {validations.sixPackDD}
                </option>
                <option value={validations.eightPackDD} data-subject={validations.eightPackDD}>
                  {validations.eightPackDD}
                </option>
                <option value={validations.twelvePackDD} data-subject={validations.twelvePackDD}>
                  {validations.twelvePackDD}
                </option>
                <option value={validations.twentyFourPackDD} data-subject={validations.twentyFourPackDD}>
                  {validations.twentyFourPackDD}
                </option>
              </Field>
              <FontAwesomeIcon icon={faChevronDown} className="fa-solid" />
            </label>
            {submitCount > 0 && errors.packageQuantity !== "" ? (
                <FieldErrorMessage error={errors.packageQuantity} />
            ) : null}
            {/* Eof package quantity */}
          </div>
          <div className={'col-12 col-md-4 form-group ' + (extraFieldsContainer? "" : "d-none")}>
            {/* Flavor name */}
            <Field type="text" className="extra-fields-js form-control" name="flavorName"placeholder={validations.flavorNameDD}
             onChange={(event) => {
              const flavorNameValue=preventNumbersAndSpecialChars(event);
              setFieldValue("flavorName",flavorNameValue)
            }}
            />
            {submitCount > 0 && errors.flavorName !== "" ? (
                <FieldErrorMessage error={errors.flavorName} />
            ) : null}
            {/* Eof flavor name */}
          </div>
          {/* Eof extra fields */}
        </div>
        {/* Eof InquiryType 100000003 */}

        {/* InquiryType 100000006 - Coupons/Rebates */}
        <div className={"hidden-block form-section-info pt-3 " + (inquiryTypeID === "100000006"? "" : "toggle-hide")}>
        <div className="col-12">
          <p>
            {validations.rebateTxt01}
          </p>

          <p>
            {validations.rebateTxt02}
            <Link to={`https://whiteclaw.grabmyrebate.com/#`} target="_blank" title="www.whiteclaw.grabmyrebate.com/#">https://whiteclaw.grabmyrebate.com/#</Link>
          </p>
          <p>
            {validations.rebateTxt03_A}
            {
              validations.rebateTxt03_B!=null?
                <br />:null
            }
            {validations.rebateTxt03_B}
            {
              validations.rebateTxt03_C1!=null?
                <br />:null
            }
            {validations.rebateTxt03_C1}
            {
              validations.rebateTxt03_C2!=null?
                <br />:null
            }
            {validations.rebateTxt03_C2}
            {
              validations.rebateTxt03_C3!=null?
                <br />:null
            }
            {validations.rebateTxt03_C3}
            {
              validations.rebateTxt03_D!=null?
                <br />:null
            }
            {validations.rebateTxt03_D}
          </p>
        </div>
        </div>
        {/* Eof InquiryType 100000006 */}

        {/* InquiryType 100000012 - Can't Find Products */}
        <div className={"hidden-block form-section-info pt-3 " + (inquiryTypeID === "100000012"? "" : "toggle-hide")}>
            <p>
                {validations.cantFindTxt01_A} <Link to={lang===LANG.EN ? '/locator' :`/es/locator`} target="_blank" title="{validations.cantFindLocatorUrlTxt}">{validations.cantFindLocatorUrlTxt}</Link> {validations.cantFindTxt01_B}
            </p>
        </div>
        {/* Eof InquiryType 100000012 */}

        {/* InquiryType 100000002 - Ingredients/Nutrition Info */}
        <div className={"hidden-block form-section-info pt-3 " + (inquiryTypeID === "100000002"? "" : "toggle-hide")}>
            <p>
                {validations.nutritionTxt01_A} <Link to={lang===LANG.EN ? `https://ussupport.whiteclawzero.com/support/home` : '/es/faq'} target="_blank" title="FAQ">{validations.nutritionFaqUrlTxt}</Link> {validations.nutritionTxt01_B}
            </p>
        </div>
        {/* Eof InquiryType 100000002 */}

        {/* InquiryType 100000005 - Sponsorship Request */}
        <div className={"hidden-block form-section-info pt-3 " + (inquiryTypeID === "100000005"? "" : "toggle-hide")}>
          <h3>{validations.sponsorTxt01}</h3>
          <p>{validations.sponsorTxt02}</p>
        </div>
        {/* Eof InquiryType 100000005 */}

        {/* InquiryType 100000007 - Partnership/Media Inquiry */}
        <div className={"hidden-block form-section-info pt-3 " + (inquiryTypeID === "100000007"? "" : "toggle-hide")}>
          <h3>{validations.partnerTxt01}</h3>
          <p>{validations.partnerTxt02}</p>
        </div>
        {/* Eof InquiryType 100000007 */}

        {/* InquiryType 100000008 - Expiration Date */}
        <div className={"hidden-block form-section-info " + (inquiryTypeID === "100000008"? "" : "toggle-hide")}>
          <p className="pb-3">
            {validations.expirationTxt01}
            &nbsp;
            {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
            <a href="#" onClick={(e) => openWindowModal(e, 'batchimage')}>
              {validations.viewImage}
            </a>.
          </p>
          <p className='pb-3'>
            {validations.expirationTxt02}
          </p>
          <p className="form-btn-container pb-4">
            <button type="button" className="form-btn form-btn-long-text" onClick={(e) => openWindowModal(e, 'decipherbatchnumber')}>{validations.decipherBatch}</button>
          </p>

          {/* WindowModal Component Batch / Decipher */}
          {/*<WindowModal
              showWindowModal={showWindowModal}
              closeWindowModal={closeWindowModal}
              windowModalType={windowModalType}
              lang={lang}
          />*/}
        </div>
        {/* Eof InquiryType 100000008 */}

        {/* Do Not Sell or Share My Personal Information  */}
        <div className={"hidden-block form-section-info " + (inquiryTypeID === '100000023' ? '' : 'toggle-hide')}>
          <div className="col-12" id ="hidden-field-option">
            {lang == "es-mx"?
            <p className='pb-5' >
              Ciertas leyes estatales le permiten realizar una solicitud relacionada con la información personal que hemos recopilado sobre usted.
               Tiene la opción de darse de baja de la venta e intercambio de su información personal, tal y como exige la legislación estatal aplicable. 
               La información facilitada a continuación nos ayudará a tramitar su solicitud.
              Si no facilita la información solicitada a continuación, es posible que no podamos identificarlo y tramitar su solicitud de darse de baja. Su solicitud de darse de baja de la venta/intercambio es específica para cada navegador y dispositivo.
              <br/>
              Para obtener más información sobre nuestras prácticas de privacidad, consulte nuestra <a href='/es/privacy' target='_blank' title='Política de Privacidad'>Política de Privacidad</a>.
            </p>
            :
            <p className='pb-5'>
              Certain state laws allow you to make a request related to the personal information that we have collected about you.
              You have the option to opt out of sale and sharing of your personal information, as required by applicable state laws.
              The information provided below will help us process your request. If you do not provide the information requested below,
              we may not be able to identify you and process your request to opt-out.  You request to opt-out of sale/sharing is browser
              and device specific.
              <br/>
              For more information about our privacy practices, please review our <a href='/privacy' target='_blank' title='Privacy Policy'>Privacy Policy</a>.
            </p>
            }
            
          </div>
        </div>

        {/* Delete My Personal Information */}
        <div className={"hidden-block form-section-info " + (inquiryTypeID === '100000024' ? '' : 'toggle-hide')}>
          <div className="col-12">
            {lang == 'es-mx'?
            <p className='pb-5'>Salvo determinadas excepciones, tiene derecho a solicitar que eliminemos cualquier parte de su Información Personal. Una vez que recibamos su solicitud y confirmemos su identidad, revisaremos su solicitud para ver si se aplica una excepción en virtud de la legislación aplicable que nos permita conservar la información. Eliminaremos o desidentificaremos de nuestros registros la Información Personal que no esté sujeta a una de estas excepciones. No discriminamos a las personas que ejercen sus derechos; tenga en cuenta que la eliminación de su Información Personal puede resultar en una experiencia menos personalizada, así como en menos comunicaciones con Mark Anthony.</p>:
            <p className='pb-5'>Subject to certain exceptions, you have the right to request that we delete any of your personal information. Once we receive your request and confirm your identity, we will review your request to see if an exception under applicable law allowing us to retain the information applies. We will delete or deidentify personal information not subject to one of these exceptions from our records. We do not discriminate against individuals who exercise their rights. Please know that deletion of your personal information may result in  less personalized experience as well as less communications with Mark Anthony.</p>
            }
          
          </div>
        </div>

        {/* Access My Personal Information */}
        <div className={"hidden-block form-section-info " + (inquiryTypeID === '100000025' ? '' : 'toggle-hide')}>
          <div className="col-12">
            {lang == 'es-mx'?
            <p className='pb-5'>
              Solicite Información Personal concreta que hemos recopilado sobre usted durante los últimos 12 meses. 
              Para proteger la Información Personal de nuestros clientes, estamos obligados a verificar su identidad antes de poder dar curso a su solicitud.
            </p>:
            <p className='pb-5'>
            Request the specific pieces of Personal Information that we have collected about you for the last 12 months.
            To protect our customers’ personal information, we are required to verify your identify before we can act on your request.
          </p>}
            
          </div>
        </div>

        {/* Correct My Personal Information */}
        <div className={"hidden-block form-section-info " + (inquiryTypeID === '100000026' ? '' : 'toggle-hide')}>
          <div className="col-12">
            {lang == 'es-mx'?
            <p className='pb-5'>
             Solicite la Información Personal concreta que debe corregirse. 
             Los tipos de datos a modificar incluyen: nombre, apellidos, número de teléfono móvil, fecha de nacimiento, dirección de correo electrónico principal, 
             dirección postal principal. 
             Salvo determinadas excepciones, tiene derecho a solicitar que corrijamos Información Personal inexacta que hayamos recopilado sobre usted; 
             también podemos solicitar información adicional que demuestre que la información que desea corregir es inexacta.
            </p>
            :
            <p className='pb-5'>
            Request the specific pieces of Personal Information that should be corrected.
            Data types to change include: First Name, Last Name, Mobile Phone Number, Date of Birth, Primary Email Address,
            Primary Mailing Address.
            Subject to certain exceptions, you have the right to request that we correct inaccurate personal information that we have collected about you.
            We may also request additional information showing that the information you want to correct is inaccurate
          </p>}
          
          </div>
        </div>

        {/* Transfer My Personal Information */}
        <div className={"hidden-block form-section-info " + (inquiryTypeID === '100000027' ? '' : 'toggle-hide')}>
          <div className="col-12">
            {lang == "es-mx"?
            <p className='pb-5'>
              Solicite la presentación de su Información Personal en un formato estructurado, de uso común y exportable que pueda transferirse a otra empresa.
            </p>:
             <p className='pb-5'>
             Request to produce your Personal Information in a structured, commonly used, exportable format that may be transferred to another business.
           </p>
           }
           
          </div>
        </div>

      </>
  )
}

export default HiddenFields

